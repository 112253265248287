/**
 * Header
 */

.site-header {
  position: fixed;
  height: 64px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  background: #fff;
  z-index: 1;
  line-height: 64px;
  font-size: 20px;
  box-sizing: border-box;

  text-align: center;

  a {
    text-decoration: none;
  }
}

.title {
  float: left;
}

@media only screen and ( max-width: 520px ) {
  .logo {
    display: none;
  }

  .site-header {
    position: absolute;
  }
}

.site-nav {
  float: right;
  font-size: 16px;
  font-weight: 400;
}

.sidebar-nav-item {
  color: black;
  display: inline-block;
}

.sidebar-nav-item {
  padding: 0 10px;
}

/**
 * Home
 */

.info h1 {
  font-weight: 400;
}

.logo {
  position: absolute;
  left: calc(50% - 24px);
}

.avatar {
  height: 64px;
  border-radius: 64px;
  vertical-align: middle;
}

.container {
  max-width: 820px;
  padding: 0 20px;
  margin: 0 auto;
}

.post-list {
  list-style: none;
}

.blog {
  border: 1px #e1e4e8 solid;
  padding: 0 16px;
  margin-bottom: 20px;
}

/**
 * Pagination
 */
.pagination {
  margin: 0;
  min-height: 2em;
  display: flex;
}

.pagination .previous {
  flex: 1;
  text-align: right;
  border-bottom: none;
}

.pagination .next {
  flex: 1;
  text-align: left;
  border-bottom: none;
}

.journal-list {
  padding-top: 1em;
}

.journal {
  margin-bottom: 3em;
}

.journal .journal-link {
  font-weight: 300;
}

.blog .post-link {
  font-weight: 300;
}

.journal, .journals {
  margin-bottom: 4em;
}

/**
 * Post
 */
.post-title {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}

.text-left {
  text-align: left;
}

/**
 * Archive
 */
.archive {
  float: right;
}

/**
 * reward
 */
.reward-section {
  margin-top: 32px;
  margin-bottom: 48px;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.reward-button {
  width: 36px;
  height: 36px;
  margin-top: 14px;
  color: #fff;
  background-color: #ea6f5a;
  border-radius: 22px;
  border: none;
  transition: all 0.2s ease-out;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
}

.reward-button:hover {
  background-color: #f14e32;
  transform: translateY(-4px);
  box-shadow: 0 0 4px rgba(0,0,0,0.14), 0 4px 8px rgba(0,0,0,0.28);
}

#qr div {
  display: inline-block;
  margin: 2px 32px;
}

.reward {
  text-align: center;
  color: #999;
  padding: 0.6em 0 0.8em 0;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.footer {
  text-align: center;
  margin: 6em 0 4em 0;
}

.copyright {
  text-transform: uppercase;
}

.bar {
  width: 1px;
  height: 14px;
  background: #e0e0e0;
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 0;
}
