@media (prefers-color-scheme: dark) {
  html, body {
    background-color: #0d1117;
    color: #c9d1d9;
  }

  .site-header {
    background: #161b22;
    border-bottom: none;

    a {
      color: #f6f0fc;
    }

    img {
      background-color: #c9d1d9;
    }
  }

  h1, h2, h3, h4, h5, em {
    color: #c9d1d9;
  }

  .typo-em, .typo em, legend, caption {
    color: #c9d1d9;
  }

  strong {
    color: #f6f0fc;
  }

  .avatar {
    height: 48px;
    border-radius: 12px;
    vertical-align: middle;
  }

  .post-meta {
    color: #c9d1d9;
  }

  .blog {
    border: 1px #c9d1d9 solid;
  }

  .footer {
    color: #8b949e;
  }

  .reward-section {
    border-top: 1px solid rgba(255, 255, 255, 0.13);
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  }

  hr {
    border-bottom: 1px solid #c9d1d9;
  }

  .bar {
    background: #c9d1d9;
  }

  .typo table thead th, .typo-table thead th {
    background: #c9d1d9;
  }
}
