
html, body {
  margin: 0;
  padding: 0;
}

body {
  padding-top: 60px;
  font-size: 1.1em;
  font-family: -apple-system, BlinkMacSystemFont, SFProDisplay-Regular;
}

hr {
  border-bottom: 1px solid #eaecef;
}

table {
  word-break: break-all;
}

.typo .post-list {
  margin-left: 0;
  list-style-type: decimal;
  list-style-position: inside;
}

pre {
  border: none;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.highlight {
  color: #386;
}

blockquote {
  color: #666;
}
